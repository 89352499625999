<template>
    <v-container class="mx-auto">
        <div class="" v-if="showheader === true">
            <h1 class="primary--text">
                {{ header.title }}
            </h1>
            <span class="">
                -- {{ header.subtitle }}
            </span>
        </div>
        <div class="my-10"></div>
        <div class="d-flex flex-row">
            <form class="flex flex-col  gap-3">
                <v-select v-model="select_filter" dense outlined hide-details :label="header.search.domain.text"
                    :items="select_list_filter" item-value="id" item-text="name_en" return-object class="mb-8 mr-2"></v-select>
            </form>
            <div class="d-flex flex-row w-full">
                <v-text-field v-model="inputTitle" :prepend-inner-icon="header.search.offer.icon"
                    :placeholder="header.search.offer.text" solo hide-details dense flat clearable>
                </v-text-field>
            </div>
            <div class="ml-4">
                <v-btn tile color="primary" class="search-field-btn rounded" @click="actionSearchBtn"
                    :loading="loadingSearch">
                    <v-icon>
                        {{ icons.mdiMagnify }}</v-icon>
                    Search
                </v-btn>
            </div>
        </div>

        <span class="error--text text-caption">{{ emptyFieldData }}</span>
        <div class="my-4">
            <!-- <v-switch v-model="showdomain" color="secondary" label="Advanced search" class="ma-0 pa-0" hide-details>
            </v-switch>
            <v-row v-if="showdomain"> -->
                <!-- <v-col md="4">
                    <p>
                        Search by activity sector
                    </p>
                    <v-select v-model="select_filter" dense outlined hide-details :label="header.search.domain.text"
                        :items="select_list_filter" item-value="id" item-text="name_en" return-object class="mb-8"></v-select>
                </v-col> -->

                <!-- <v-col md="4">
                    <p>
                        Search by location
                    </p>
                    <v-text-field v-model="inputLocation" :prepend-inner-icon="header.search.location.icon"
                        :placeholder="header.search.location.text" solo hide-details dense flat clearable>
                    </v-text-field>
                    <div class="input-search-float-relative" ref="clickOutSideSearch">
                        <div v-if="inputLocation && (showSearch == true)" class="input-search-float-item">
                            <span class="item" v-for="fruit in filteredLoation()" :key="fruit"
                                @click="inputLocation = fruit, showSearch = false">
                                {{ fruit }}
                            </span>
                        </div>
                    </div>
                </v-col> -->
                <!-- <v-col md="4">
                    <p>
                        Search by company
                    </p>
                    <v-text-field v-model="inputCompany" :prepend-inner-icon="header.search.company.icon"
                        :placeholder="header.search.company.text" solo hide-details dense flat clearable>
                    </v-text-field>
                    <div class="input-search-float-relative" ref="clickOutSideSearch">
                        <div v-if="inputCompany && (showSearch == true)" class="input-search-float-item">
                            <span class="item" v-for="fruit in filteredCompany()" :key="fruit"
                                @click="inputCompany = fruit, showSearch = false">
                                {{ fruit }}
                            </span>
                        </div>
                    </div>
                </v-col> -->
            <!-- </v-row> -->

        </div>
        <div class="d-flex flex-row" v-if="isBtnResultVisible === true">
            <span>{{ searchDetails }}</span>
            <v-spacer></v-spacer>
            <v-btn small text color="error" class="lightError" @click="closeSearchResult" :disabled="false">
                <v-icon>{{ icons.mdiClose }}</v-icon>
                <span>close result</span>
            </v-btn>
        </div>
    </v-container>

</template>
<script>
import { mdiDomain, mdiClose, mdiAccountNetwork, mdiMagnify, mdiMapMarkerOutline } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
// import { Externalapi } from '@/plugins/Externalapi'
import { ref } from "vue"

export default {
    props: {
        header: {
            type: Object,
            default: () => { }
        },
        loader: {
            type: Boolean,
            default: false
        },
        result: {
            type: String,
            default: ''
        },
        showheader: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showSearch: false,
            isBtnResultVisible: false,
            isReadyToSearch: false,
            emptyFieldData: '',
            showdomain: true,
            inputTitle: "",
            inputLocation: "",
            inputCompany: "",

            select_filter: "",
            select_list_filter: [],
            loadingData: true,

            inputDomain: "Business Et Entreprenariat",
            icons: { mdiDomain, mdiClose, mdiAccountNetwork, mdiMagnify, mdiMapMarkerOutline },
            searchData: {
                title: {
                    isEmpty: true,
                    value: ''
                },
                location: {
                    isEmpty: true,
                    value: ''
                },
                domain: {
                    isEmpty: true,
                    value: ''
                },
                company: {
                    isEmpty: true,
                    value: ''
                },
            },

            inputmodel: "",
            comp_name: [],
            locations_name: []
        }
    },
    watch: {
        inputCompany(newValue, oldValue) {
            if (this.inputCompany.length > 1) {
                this.showSearch = true
            }
        },
        inputLocation(newValue, oldValue) {
            if (this.inputLocation.length > 1) {
                this.showSearch = true
            }
        }
    },
    computed: {
        loadingSearch() {
            return this.loader
        },
        searchDetails() {
            return this.result
        }
    },
    methods: {
        // Listener to float search container
        handleClickOutside(event) {
            if (!this.$refs.clickOutSideSearch.contains(event.target)) {
                this.showSearch = false
            }
        },


        filteredCompany() {
            if (this.inputCompany.length > 0) {
                this.showSearch = true
                return this.comp_name.filter((item) => item.toLowerCase().includes(this.inputCompany.toLowerCase()))
            }
            else {
                this.showSearch = false
                return []
            }
        },
        filteredLoation() {
            if (this.inputLocation.length > 0) {
                this.showSearch = true
                return this.locations_name.filter((item) => item.toLowerCase().includes(this.inputLocation.toLowerCase()))
            }
            else { return [] }
        },
        checkInputTitle() {
            if (this.inputTitle) {
                if (this.inputTitle.trim()) {
                    this.searchData.title.isEmpty = false
                    this.searchData.title.value = this.inputTitle
                    this.readyToSearch = true
                }
                else {
                    this.searchData.title.isEmpty = true
                }
            }
            else {
                this.searchData.title.isEmpty = true
            }
        },

        checkInputLocation() {
            if (this.inputLocation) {
                if (this.inputLocation.trim()) {
                    this.searchData.location.isEmpty = false
                    this.searchData.location.value = this.inputLocation
                    this.readyToSearch = true
                }
                else {
                    this.searchData.location.isEmpty = true
                }
            }
            else {
                this.searchData.location.isEmpty = true
            }
        },
        checkInputDomain() {
            if (this.showdomain) {
                this.searchData.domain.isEmpty = false
                this.searchData.domain.value = this.inputDomain
                this.readyToSearch = true
            }
            else {
                this.searchData.domain.isEmpty = true
            }
        },

        checkInputCompany() {
            if (this.inputCompany) {
                if (this.inputCompany.trim()) {
                    this.searchData.company.isEmpty = false
                    this.searchData.company.value = this.inputCompany
                    this.readyToSearch = true
                }
                else {
                    this.searchData.company.isEmpty = true
                }
            }
            else {
                this.searchData.company.isEmpty = true
            }
        },
        actionSearchBtn() {
            this.checkInputTitle()
            this.checkInputLocation()
            this.checkInputDomain()
            this.checkInputCompany()

            this.isBtnResultVisible = true
            this.emptyFieldData = ''

            this.$emit('action', this.searchData)
        },
        closeSearchResult() {
            this.$emit('close', null)
            this.isBtnResultVisible = false

            // this.showdomain = false
            this.inputLocation = ''
            this.inputTitle = ''
            this.inputCompany = ''
        },
    },
    mounted() {
        // add lisctenner to floate search container
        document.addEventListener('click', this.handleClickOutside);

        //  Others
        this.loadingData = true
        Drequest.apiafro(`lazyloading.academies?dfilters=on&next=1&per_page=all`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach(element => {
                        this.select_list_filter.push(element)
                    });
                    this.loadingData = false
                }
                else {
                    this.$fire({
                        type: "error",
                        text: "Error occured..!",
                        timer: 3000
                    })
                    this.loadingData = false
                }
            })
            .catch((err) => {
                console.log(err)
                this.loadingData = false;
            })

        Drequest.api(`lazyloading.enterprise`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((element, index) => {
                        this.comp_name.push(element.name)

                    });
                }
            })
            .catch((err) => {
            });
        Drequest.api(`lazyloading.country`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((element, index) => {
                        this.locations_name.push(element.name)

                    });
                }
            })
            .catch((err) => {
            });
    },
    beforeMount() {
        // add lisctenner to floate search container
        document.removeEventListener('click', this.handleClickOutside);

        //  Others
    },
    setup() {

        return {}
    }
}
</script>

<style lang="scss" scoped>
//@import '@/styles/styles.scss';

.input-search-float-relative {
    position: relative;
    width: 100%;
}

.search-option {
    border: solid #3a3939 1px;
    padding: 0px 5px;
    border-radius: 10px;
    margin-right: 10px;
}

.input-search-float-item {
    position: absolute;
    margin-top: 5px;
    border-radius: 5px;
    display: block;
    background: var(--v-secondaryAccent-base);
    z-index: 50;

    height: 120px;
    //overflow:scroll;
    overflow-x: hidden;

    height: auto;
    max-height: calc(1em * 10);
    width: 100%;

    .item {
        display: block;
        width: 100%;
        cursor: pointer;
        padding: 0px 1em;
    }

    .item:last-child {
        border-end-end-radius: 5px;
        border-end-start-radius: 5px;
    }

    .item:first-child {
        border-start-start-radius: 5px;
        border-top-right-radius: 5px;
    }

    .item:hover {
        background: var(--v-primary-base);
        color: var(--v-secondaryAccent-base);
        padding: 3px 1em;
    }

}
</style>
