<template>
  <div class="">
    <!-- <header-page :header="headers"></header-page> -->
    <v-container>

      <v-row>
        <v-col class="mx-4">
          <header-search :header="headersSearch" :loader="loadSearchResult" :result="searchDetails"
            :showheader="false" @close="closeSearch" @action="searchActions">
          </header-search>
        </v-col>
      </v-row>

      <v-row class="mx-auto" v-if="loaderdata === false && no_resultitem <= 0">
        <v-col cols="8" class="">
          <empty-data :text="nodatas"></empty-data>
        </v-col>
      </v-row>

      <v-row class="px-2 py-4 mx-auto">
        <!-- Proposal's list -->

        <v-col cols="12" v-if="loaderdata === false">
          <v-pagination class="text-center" circle v-model="page" :length="pages" :total-visible="visiblePages"
            @input="updateProposalsPage"></v-pagination>
        </v-col>
        <v-col v-if="loaderdata === false">
          <div v-for="(item, i) in proposalItems" :key="i">
            <proposal-item-card :reoi="item" class="pb-2"></proposal-item-card>
          </div>
        </v-col>
        <v-col v-else>
          <v-skeleton-loader v-for="i in 5" :key="i" class="mx-auto mb-2" max-width="100%" type="article">
          </v-skeleton-loader>
        </v-col>
        <!-- <v-col cols="4">
              <v-skeleton-loader v-for="i in 6" class="mx-auto  mb-2" max-width="100%" type="article"
                v-if="externalProposals.loader === true">
              </v-skeleton-loader>
              <div v-for="(item, k) in externalProposals.datas" :keys="k">
                <v-hover class="mb-1">
                  <v-card outlined rounded @click="selectExternalProposal(item)">
                    <v-card-text class="secondary--text">{{ item.title }}</v-card-text>
                  </v-card>
                </v-hover>
              </div>
            </v-col> -->
      </v-row>
      <v-row>
        <v-col class="mb-4">
          <v-pagination class="text-center" circle v-model="page" :length="pages" :total-visible="visiblePages"
            @input="updateProposalsPage"></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mdiClose, mdiMagnify, mdiDomain, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiMapMarkerOutline, mdiOpenInNew } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
import CheckRoleDialog from '@/views/components/CheckRoleDialog.vue';
// import ReplyProposal from '@/views/pages/company/components/ReplyProposal.vue';
import SlideComponent from '@/views/pages/components/SlideComponent.vue'
import HeaderSearch from '@/views/dashboard/components/HeaderSearch.vue'
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import ProposalItemCard from '@/views/components/proposals/ProposalItemCard.vue';
import EmptyData from '@/views/components/utils/EmptyData.vue';

export default {
  components: {
    CheckRoleDialog,
    HeaderSearch,
    HeaderPage,
    SlideComponent,
    ProposalItemCard,
    EmptyData
  },
  data() {
    return {

      no_resultitem: 0,
      searchDetails: '',
      nodatas: "call for proposals",
      loadSearchResult: false,
      loaderdata: false,
      submit: true,
      absolute: true,
      overlay: false,
      reoiP: {},
      externalProposals: {
        loader: true,
        size: 5,
        datas: []
      },
      headersSearch: {
        title: "Most recently calls for proposals",
        subtitle: "Choose from over the most recently calls for proposals by companies!",
        icon: '',
        search: {
          offer: {
            text: 'Search by offer',
            icon: mdiMagnify
          },
          location: {
            text: 'Search by location',
            icon: mdiMapMarkerOutline
          },
          domain: {
            text: 'Search by domain',
            icon: mdiDomain
          },
          company: {
            text: 'Search by company',
            icon: mdiDomain
          },
        },
      },
      headers: {
        titlepage: 'More proposals here',
        icon: this.icons.mdiDomain
      },
      proposalslist: [],
      tmpsproposalslist: [],
      loadingRfpEnterprise: false,

      page: 1,
      pageSize: 5,
      visiblePages: 8,
      historyProposals: [],
      listCount: 0,
    }
  },

  computed: {
    proposalItems() {
      return this.historyProposals
    },
    length() {
      return 7000
    },
    pages() {
      if (this.pageSize == null || this.listCount == null) return 0;
      return Math.ceil(this.listCount / this.pageSize);
    }
  },
  methods: {
    selectExternalProposal(item) {
      var t_link = Utils.replaceAll(item.link, '/', '')
      t_link = Utils.replaceAll(item.link, 'fr', '')

      this.$router.push({ name: 'proposal.external.details', params: { offer_title: t_link } })
    },
    closeSearch() {
      this.loaderdata = true
      this.proposalslist = this.tmpsproposalslist
      this.initPagination()
      this.no_resultitem = 0
      this.loader.loaderdata = false
      // this.loadInitialProposals()
    },

    searchActions(data) {
      this.loadSearchResult = true
      this.loaderdata = true
      const s_offer = data.title.isEmpty === false ? data.title.value : ''
      const s_domain = data.domain.isEmpty === false ? data.domain.value : ''
      const s_location = data.location.isEmpty === false ? data.location.value : ''
      const s_company = data.company.isEmpty === false ? data.company.value : ''

      this.tmpsproposalslist = this.proposalslist
      this.proposalslist = []
      Drequest.api(`rfp.search?offer=${s_offer}&domain=${s_domain}&location=${s_location}&company=${s_company}`)
        .get((response) => {
          if (response.success === true) {
            response.listEntity.forEach((element, index) => {
              this.proposalslist.push(element)

            });
            this.reoiP = response.listEntity[0]
            this.searchDetails = response.detail
            this.no_resultitem = response.nb_element
            this.loadSearchResult = false
            this.loaderdata = false
          }
          else {
            this.loadSearchResult = false
            this.searchDetails = response.detail
            this.loaderdata = false
            this.no_resultitem = 0
          }

        })
        .catch((err) => {
          this.loadSearchResult = false
          this.loaderdata = false

          this.no_resultitem = 0
        });
      this.initPagination();
    },

    selectedCallForProposal(value) {
      this.$router.push({ name: 'proposal.details', params: { offer_id: value.id, offer_title: Utils.replaceAll(value.title, ' ', '-') } })
      this.reoiP = value
    },
    loadInitialProposals() {
      this.loaderdata = true
      Drequest.api(`rfp.combinealls`)
        .get((response) => {
          if (response.success === true) {
            this.proposalslist = response.listEntity
            this.loaderdata = false
            this.no_resultitem = this.proposalslist.length

            this.initPagination();

          }
          else {
            this.loaderdata = false
            this.no_resultitem = 0
          }
        })
        .catch((err) => {
          this.loaderdata = false
          this.loaderdata = false
        });
    },
    loadExternalsProposals() {
      this.externalProposals.loader = true
      Drequest.api(`external.allsproposal`)
        .get((response) => {
          if (response.success === true) {
            response.listEntity.forEach((element, index) => {
              this.externalProposals.datas.push(element)
            });
            this.externalProposals.loader = false
            this.externalProposals.size = response.nb_element
          }
          else {
            this.externalProposals.loader = false
            this.externalProposals.size = 0
          }
        })
        .catch((err) => {
          this.externalProposals.loader = false
          this.externalProposals.size = 0
        });
    },

    initPagination() {
      this.listCount = this.proposalslist.length;
      if (this.listCount < this.pageSize) {
        this.historyProposals = this.proposalslist;
      } else {
        this.historyProposals = this.proposalslist.slice(0, this.pageSize);
      }
    },
    updateProposalsPage(pageIndex) {
      let _start = (pageIndex - 1) * this.pageSize;
      let _end = pageIndex * this.pageSize;
      this.historyProposals = this.proposalslist.slice(_start, _end);
      this.page = pageIndex;
    }
  },

  beforeMount() {
    this.loadInitialProposals()
    this.updateProposalsPage(this.page);
  },
  setup(props) {
    const icons = { mdiClose, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiDomain, mdiMapMarkerOutline, mdiOpenInNew }

    return {
      icons
    }
  }
}
</script>

<style lang="scss" scoped>
</style>