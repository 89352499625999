<template>
    <div>

        <!-- Snippet -->
        <!-- <div class="h-full"> -->
        <!-- Card -->
        <!-- <div class="h-full max-w-sm mx-auto bg-white shadow-lg rounded-sm border border-gray-200">
            <div class="flex flex-col h-full">
                <div class="flex-grow p-5">
                    <div class="flex justify-between items-start">
                        <header>
                            <div class="flex mb-2">
                                <a class="relative inline-flex items-start mr-5" href="#0">
                                    <img class="rounded-full" src="https://preview.cruip.com/mosaic/images/user-64-01.jpg" width="64" height="64" alt="User 01" />
                                </a>
                                <div class="mt-1 pr-1">
                                    <a class="inline-flex text-gray-800 hover:text-gray-900" href="#0">
                                        <h2 class="text-xl leading-snug justify-center font-semibold">Dominik McNeail</h2>
                                    </a>
                                    <div class="flex items-center"><span class="text-sm font-medium text-gray-400 -mt-0.5 mr-1">-&gt;</span> <span>🇮🇹</span></div>
                                </div>
                            </div>
                        </header>
                        <div class="relative inline-flex flex-shrink-0" >
                                <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                                    <circle cx="16" cy="16" r="2" />
                                    <circle cx="10" cy="16" r="2" />
                                    <circle cx="22" cy="16" r="2" />
                                </svg>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="text-sm">Fitness Fanatic, Design Enthusiast, Mentor, Meetup Organizer & PHP Lover.</div>
                    </div>
                </div>
                <div class="border-t border-gray-200">
                    <div class="flex divide-x divide-gray-200r">
                        <a class="block flex-1 text-center text-sm text-indigo-500 hover:text-indigo-600 font-medium px-3 py-4" href="#0">
                            <div class="flex items-center justify-center">
                                <svg class="w-4 h-4 fill-current flex-shrink-0 mr-2" viewBox="0 0 16 16">
                                    <path d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                                </svg>
                                <span>Send Email</span>
                            </div>
                        </a>
                        <a class="block flex-1 text-center text-sm text-gray-600 hover:text-gray-800 font-medium px-3 py-4 group" href="#0">
                            <div class="flex items-center justify-center">
                                <svg class="w-4 h-4 fill-current text-gray-400 group-hover:text-gray-500 flex-shrink-0 mr-2" viewBox="0 0 16 16">
                                    <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                                </svg>
                                <span>Edit Profile</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- </div> -->


        <v-card outlined class="usersm-card" v-if="user">
            <v-card-text class="d-flex align-center text-center justify-center">
                <v-avatar size="4.5em" color="white" class="center">
                    <v-img :src="srclogo" :lazy-src="srclogo" aspect-ratio="1" class="subsecondary lighten-2">
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-skeleton-loader v-bind="attrs" type="image" height="100%" width="100%"
                                    class="mx-auto rounded-pill"></v-skeleton-loader>
                            </v-row>
                        </template>
                    </v-img>
                </v-avatar>
            </v-card-text>
            <div class="usersm">
                <v-card-text align="center" justify="center">
                    <!-- {{'@/assets/images/flags/' + userInfos.country.iso + '.svg'}} -->
                    <h3 class="text-capitalize text-center na">{{ userInfos.firstname }}</h3>
                    <div class="d-flex flex-row text-center">
                        <v-icon size="1em" color="primary">
                            {{ icons.mdiMapMarkerOutline }}
                        </v-icon>
                        <span class="ml-2 text-caption">
                            {{ userInfos.country.name }}
                        </span>
                    </div>
                    <div v-if="userInfos.country">
                        <v-img :src="require('@/assets/images/flags/' + userInfos.country.iso + '.svg')"
                            :lazy-src="'@/assets/images/flags/' + userInfos.country.iso + '.svg'" aspect-ratio="1"
                            width="1.6em" height="1em" class="text-center subsecondary lighten-2">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-skeleton-loader v-bind="attrs" type="image" height="100%" width="100%"
                                        class="mx-auto rounded-pill"></v-skeleton-loader>
                                </v-row>
                            </template>
                        </v-img>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn x-small block outlined color="secondary" class="text-caption subsecondary rounded-lg px-2"
                        @click="goToreferenceLink(link)">
                        Details</v-btn>
                </v-card-actions>
            </div>
        </v-card>

    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCartPlus, mdiMapMarkerOutline } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
var md5 = require("md5")

export default {
    props: {
        user: {
            type: Object,
            default: () => { },
        },
        link: {
            type: Object,
            default: undefined
        },
    },
    data() {
        return {
            srclogo: '',
            attrs: {}
        }
    },
    methods: {
        goToreferenceLink(value) {
            this.$router.push({ name: 'user.views', params: { ids: this.userInfos.id, f_id: this.userInfos.firstname } })
        },

        loadlogo: function (e) {
            Drequest.api(`user.loadlogo?user_id=${this.userInfos.id}`).get((response) => {
                if (response.success === true) {
                    this.srclogo = response.logo
                }
                else { }
            }).catch((err) => { });
        },

    },
    beforeMount() {
        this.loadlogo()
    },
    setup(props) {
        const icons = { mdiCartPlus, mdiMapMarkerOutline }
        const userInfos = props.user
        return {
            icons, userInfos
        }
    },
}
</script>

<style lang="scss" scoped>
.usersm-card {
    border-radius: 1em !important;
    border: solid 3px var(--v-subsecondary-base);
    min-height: 100%;
    height: 100%;
    width: 10em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.usersm {
    //height: 50% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1em;
    //border: solid 1px #000;

    h1 {
        color: var(--v-primary-base) !important;
    }

    .h5 {
        color: var(--v-subprimary-base) !important;
    }
}
</style>